<template>
  <v-row
    style="display: contents;"
    class="mb-12"
  >
    <div class="up-elem-container pb-8 ma-5">
      <div class="legend-label">
        <span class="label enabled">Enabled</span>
        <span class="label timeline">Timeline</span>
      </div>
      <div
        ref="elemsContainer"
        class="elems-container"
      >
        <div
          v-for="(stat, i) in upStatus"
          class="up-elem-container-elem"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on">
                <div
                  class="up-elem"
                  :class="{
                    success: stat.status && stat.in_use,
                    warning: !stat.in_use,
                    error: !stat.status && stat.in_use
                  }"
                ></div>
              </div>
            </template>
            <span>{{ stat.date }}</span>
          </v-tooltip>
          <span class="timeline-elem">
            {{ __showTimelineElem(i) ? _getProcessedDate(stat.date) : '' }}
          </span>
        </div>
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  props: ['upStatus'],
  data() {
    return {
      showTimeEach: 5,
    }
  },
  created() {
    const interval = setInterval(() => {
      if (this.$refs.elemsContainer) {
        setTimeout(() => {
          this.$refs.elemsContainer.scrollLeft = 100000
        }, 100)
        clearInterval(interval)
      }
    }, 100)
  },
  updated() {
    const interval = setInterval(() => {
      if (this.$refs.elemsContainer) {
        setTimeout(() => {
          this.$refs.elemsContainer.scrollLeft = 100000
        }, 100)
        clearInterval(interval)
      }
    }, 100)
  },
  methods: {
    __showTimelineElem(i) {
      const upStatusCount = this.upStatus.length
      if (this.showTimeEach > upStatusCount) {
        return (i === 0 || i === upStatusCount - 1)
      }

      return (i % this.showTimeEach === 0)
    },
    _getProcessedDate(date) {
      // @TODO: process based on full dates range
      return date.slice(11, 16)
    },
  },
}
</script>

<style scoped lang="scss">
  .legend-label {
    display: flex;
    flex-direction: column;
    padding-right: 32px;
    margin-right: auto;
    margin-top: 2px;

    .label {
      font-size: 13px;
      line-height: 13px;

      &.enabled {
        margin-top: 10px;
      }
      &.timeline {
        margin-top: 11px;
      }
    }
  }
  .up-elem-container {
    display: flex;
    position: relative;
  }
  .up-elem {
    margin: 2px;
    width: 6px;
    height: 24px;
    border-radius: 5px;
  }
  .up-elem-container-elem {
    margin-top: 2px;
    position: relative;
  }
  .elems-container {
    overflow: scroll;
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 4px;
    padding-bottom: 28px;
  }
  .timeline-elem {
    position: absolute;
    left: -6px;
    font-size: 12px;
  }
</style>
