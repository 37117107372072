<template>
  <div>
    <h3>Integrations to Server</h3>

    <v-expansion-panels focusable class="mt-4">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Linux
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.

          <code class="d-block pa-3 ps-4 mt-2">
            curl https://hub.razzzila.com/
          </code>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <h3 class="mt-8">Integrations to Project</h3>

    <v-expansion-panels focusable class="mt-4">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-img
            class="shrink mr-2"
            alt="Magento 2"
            :src="require('@/assets/images/magento2.svg')"
            width="40"/>
          Magento 2
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h2>Manual Installation</h2>

          <p class="mt-4">
            <a href="#">Download module.</a><br>
            Unzip it to Magento 2 app/code folder.
          </p>

          <p class="mt-2">
            Or unzip using terminal.<br>
            Open in terminal Magento 2 root directory and unzip module here.
          </p>
          <code class="d-block pa-3 ps-4 mt-2">
            wget @TODO:
          </code>

          <p class="mt-2">To install module run following commands:</p>

          <code class="d-block pa-3 ps-4 mt-2">
            php bin/magento maintenance:enable<br>
            php bin/magento setup:upgrade<br>
            php bin/magento setup:di:compile<br>
            php bin/magento setup:static-content:deploy<br>
            php bin/magento maintenance:disable
          </code>

          <h5 class="mt-3">
            Composer package will be published soon.
          </h5>

          <h2 class="mt-6">Module Installation Service</h2>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <h3 class="mt-8">Upcoming Integrations</h3>

    <v-expansion-panels focusable class="mt-4">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-img
            class="shrink mr-2"
            alt="Magento 2"
            :src="require('@/assets/images/laravel.svg')"
            width="40"/>
          Laravel
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Public version expect to be released in 2 month
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-img
            class="shrink mr-2"
            alt="Magento 2"
            :src="require('@/assets/images/october.png')"
            width="40"/>
          October CMS
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Public version expect to be released in 3 month
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Containers
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          We are working on tech task for it. Latest information will be there. Alternatively, send us email that you are waiting for it and we'll add you to containers newsletter list.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {}
</script>
