<template>
  <div>
    <div v-if="currentProject && currentProject.id">
      <div v-for="widgetBlock in data">
        <h2 class="text-center pa-4">
          {{ widgetBlock.name }}
        </h2>

        <v-card
          v-for="widget in widgetBlock.widgets"
          class="mt-3 mb-3"
        >
          <v-card-title>{{ widget.module.module }}</v-card-title>

          <vue-apex-charts
            v-if="widget.viewType === 'line-chart'"
            type="line"
            height="350"
            :options="generatedData[__getWidgetKey(widget)].options"
            :series="generatedData[__getWidgetKey(widget)].series"
          ></vue-apex-charts>

          <up-status
            v-if="widget.viewType === 'up-status-bar'"
            :up-status="generatedData[__getWidgetKey(widget)].upStatus"
          ></up-status>

          <down-status
            v-if="widget.viewType === 'down-status-bar'"
            :down-status="generatedData[__getWidgetKey(widget)].upStatus"
          ></down-status>
        </v-card>
      </div>
    </div>

    <v-dialog
      v-if="serverStatsSynced && serverStatsSources && serverStatsModules"
      v-model="add_modules_dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="3"
          class="w-full"
        >
          Add Integration
          <v-icon dense class="ml-2">
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </template>

      <v-card class="pa-4">
        <v-card-title class="pt-0">
          Add New Modules
          <v-btn icon class="ml-auto" @click="add_modules_dialog = false">
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <modules></modules>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div v-if="serverStatsSynced && !serverStatsSources && !serverStatsModules">
      <v-card>
        <v-card-title>
          You not collecting data from any sources yet
        </v-card-title>
        <v-card-subtitle class="pt-1">
          But start much easier then you think!
        </v-card-subtitle>
      </v-card>

      <modules class="mt-12"></modules>
    </div>

    <choose-project v-if="!currentProject || !currentProject.id"></choose-project>
  </div>
</template>

<script>
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'
import UpStatus from './Widgets/UpStatus'
import DownStatus from './Widgets/DownStatus'
import ChooseProject from '@/components/ChooseProject'
import Modules from '@/components/ServerStatus/Modules'
import {mdiPlus, mdiClose} from "@mdi/js";

export default {
  components: {
    VueApexCharts,
    UpStatus,
    DownStatus,
    ChooseProject,
    Modules,
  },
  setup() {
    return {
      add_modules_dialog: false,

      generatedData: {},
      chartOptions: {
        backgroundColor: 'transparent',
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: '18px',
          },
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          row: {
            colors: ['transparent'],
            opacity: 0.5,
          },
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          forceNiceScale: false,
          min: 0,
          max: 1,
          labels: {
            formatter: value => `${(value).toFixed(2)}%`,
          },
        },
      },
    }
  },

  data() {
    return {
      timer: false,
      loading: false,

      projects: [],

      widgetsTypeMap: {},
      widgetsMap: {},
      notConfiguredWidgets: {},
      data: {},
      widgetsData: {},
      widgetsList: [],

      icons: {
        mdiPlus,
        mdiClose
      },

      error: null,
    }
  },
  watch: {
    async currentProject() {
      await this.$store.dispatch('setSectionOverlay', true)
      this.__updateServiceInfo()
      await setTimeout(async () => {
        await this.$store.dispatch('setSectionOverlay', false)
      }, 1000)
    },
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      async () => {
        await this.$store.dispatch('setSectionOverlay', true)
        this.fetchData()
        await setTimeout(async () => {
          await this.$store.dispatch('setSectionOverlay', false)
        }, 1000)
      },

      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true },
    )

    this.timer = setInterval(this.fetchData, 60000)

    this.$store.dispatch('fetchSourcesAndModules')
  },
  computed: {
    ...mapState([
      'currentProject',

      'serverStatsSynced',
      'serverStatsSources',
      'serverStatsModules',
    ]),
  },
  beforeDestroy() {
    this.cancelAutoUpdate()
  },
  methods: {
    cancelAutoUpdate() {
      clearInterval(this.timer)
    },

    fetchData() {
      this.__updateServiceInfo()
    },

    getServiceInfo(callback) {
      axios
        .get(`get-service-info/${this.currentProject.id}`)
        .then(response => callback(null, response.data))
        .catch(error => callback(error))
    },
    __updateServiceInfo() {
      // return; // @TODO: remove
      if (!this.currentProject) {
        return
      }

      this.error = null

      this.loading = true
      this.getServiceInfo((err, response) => {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          this.widgetsTypeMap = response.data.widgets_type_map
          this.widgetsMap = response.data.widgets_map
          this.notConfiguredWidgets = response.data.not_configured_widgets

          // @TODO: add additional processing of data
          const widgetsData = {}
          response.data.data.map(widget => {
            const key = this.__getWidgetKey(widget)
            switch (widget.viewType) {
              case 'line-chart':
                const chartOptionsCopy = JSON.parse(JSON.stringify(this.chartOptions))
                chartOptionsCopy.yaxis.labels.formatter = function (value) {
                  return `${(value * 100).toFixed(2)}%`
                }

                this.generatedData[key] = {
                  series: this._processSeries(widget),
                  options: chartOptionsCopy,
                }
                break

              case 'up-status-bar':
              case 'down-status-bar':
                this.generatedData[key] = {
                  upStatus: this._processUpStatus(widget),
                }
                break
            }
            widgetsData[widget.module.module] = widget
          })

          const widgetsList = []
          const usedWidgets = []
          response.data.widgets_map.map(row => {
            row.map(col => {
              if (widgetsData[col] !== undefined) {
                widgetsList.push(widgetsData[col])
                usedWidgets.push(col)
              }
            })
          })

          response.data.data.map(widget => {
            if (usedWidgets.indexOf(widget.module.module) === -1) {
              widgetsList.push(widgetsData[widget.module.module])
            }
          })

          const widgetsListBlocks = {}
          widgetsList.map(widgetListElem => {
            const key = `${widgetListElem.source.source}---${widgetListElem.source.ip}`
            if (widgetsListBlocks[key] === undefined) {
              widgetsListBlocks[key] = {
                name: widgetListElem.source.source,
                widgets: [],
              }
            }
            widgetsListBlocks[key].widgets.push(widgetListElem)
          })

          this.data = widgetsListBlocks
        }
      })
    },

    _processSeries(widget) {
      return [{
        name: widget.module.module,
        data: widget.data.lineData,
      }]
    },

    _processUpStatus(widget) {
      return widget.data.map(el => ({
        in_use: el.in_use,
        status: el.enabled,
        date: el.added_at,
      }))
    },

    __getWidgetKey(widget) {
      return `${widget.source.id}-${widget.module.id}`
    },
  },
}
</script>

<style scoped>
  .modules-btn {
    position: fixed;
    top: calc(100vh - 84px);
    right: 24px;
    z-index: 11;
  }
</style>
