var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mb-12",staticStyle:{"display":"contents"}},[_c('div',{staticClass:"up-elem-container pb-8 ma-5"},[_c('div',{staticClass:"legend-label"},[_c('span',{staticClass:"label enabled"},[_vm._v("Enabled")]),_c('span',{staticClass:"label timeline"},[_vm._v("Timeline")])]),_c('div',{ref:"elemsContainer",staticClass:"elems-container"},_vm._l((_vm.upStatus),function(stat,i){return _c('div',{staticClass:"up-elem-container-elem"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"up-elem",class:{
                  success: stat.status && stat.in_use,
                  warning: !stat.in_use,
                  error: !stat.status && stat.in_use
                }})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(stat.date))])]),_c('span',{staticClass:"timeline-elem"},[_vm._v(" "+_vm._s(_vm.__showTimelineElem(i) ? _vm._getProcessedDate(stat.date) : '')+" ")])],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }